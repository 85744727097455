<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadFarmers(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showFarmerDialog(null)">添加</el-button>
                    <el-button @click="importFarmersDialogVisible=true">导入</el-button>
                </div>
            </div>
            <el-table :data="farmers.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="企业名称" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                        <el-tag v-if="scope.row.registeredUser.hasRisk===false" type="success" size="mini"
                            effect="plain">
                            {{scope.row.registeredUser.hasRiskName}}</el-tag>
                        <el-tag v-else-if="scope.row.registeredUser.hasRisk===true" type="danger" size="mini"
                            effect="plain">
                            {{scope.row.registeredUser.hasRiskName}}</el-tag>
                        <el-tag v-else type="info" size="mini" effect="plain">
                            {{scope.row.registeredUser.hasRiskName}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="统一社会信用代码" sortable />
                <el-table-column prop="legalPersonName" label="法人" sortable />
                <el-table-column prop="legalPersonPhone" label="法人电话" sortable />
                <el-table-column prop="contactName" label="联系人" sortable />
                <el-table-column prop="contactPhone" label="联系电话" sortable />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showFarmerDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteFarmer(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="farmers.totalRecords"
                :current-page="farmersPagination.currentPage" :page-size="farmersPagination.pageSize" class="mt-3"
                @current-change="farmersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="farmerDialogVisible" title="种植户" :close-on-click-modal="false" width="800px">
            <el-form>
                <el-form-item label="注册用户" class="form-item-required">
                    <el-input :value="farmer.registeredUser.name" readonly placeholder="注册用户">
                        <template #append v-if="farmer.id==undefined">
                            <el-button @click="selectRegisteredUserDialogVisible=true">选择</el-button>
                        </template>
                    </el-input>
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="法人" class="form-item-required">
                            <el-input v-model="farmer.legalPersonName" placeholder="法人" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法人电话" class="form-item-required">
                            <el-input v-model="farmer.legalPersonPhone" placeholder="法人电话" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="联系人" class="form-item-required">
                            <el-input v-model="farmer.contactName" placeholder="联系人" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" class="form-item-required">
                            <el-input v-model="farmer.contactPhone" placeholder="联系电话" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="mt-2">
                    <div>征信相关附件</div>
                    <div class="border rounded mt-2 p-1">
                        <picture-uploader v-model="farmer.creditAttachments" />
                    </div>
                </div>

                <div class="mt-3">
                    <div class="row no-gutters">
                        <div class="col">
                            失信相关附件
                            <a href="http://zxgk.court.gov.cn/zhzxgk/" target="_blank">查询被执行人</a>
                        </div>
                        <div class="col-auto">
                            <el-radio v-model="farmer.hasRisk" :label="false">无风险</el-radio>
                            <el-radio v-model="farmer.hasRisk" :label="true">有风险</el-radio>
                        </div>
                    </div>
                    <div class="border rounded mt-2 p-1">
                        <picture-uploader v-model="farmer.discreditAttachments" />
                    </div>
                </div>

                <div class="mt-3">
                    <div>其他附件</div>
                    <div class="border rounded mt-2 p-1">
                        <picture-uploader v-model="farmer.otherAttachments" />
                    </div>
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveFarmer">确定</el-button>
                <el-button @click="farmerDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="true"
            @select="selectRegisteredUser" />

        <import-dialog v-model="importFarmersDialogVisible" title="导入" action="/api/Farmer/ImportFarmers"
            template="/templates/种植户导入模板.xls" @success="loadFarmers" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                farmers: {},
                farmersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                farmer: { registeredUser: {} },
                farmerDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
                importFarmersDialogVisible: false,
            };
        },
        methods: {
            async loadFarmers(resetPage = false) {
                if (resetPage) {
                    this.farmersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Farmer/GetEnterpriseFarmers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 3,
                        key: this.key,
                        pageIndex: this.farmersPagination.currentPage - 1,
                        pageSize: this.farmersPagination.pageSize,
                    }
                });
                this.farmers = response.data;
            },
            farmersPaginationCurrentChange(page) {
                this.farmersPagination.currentPage = page;
                this.loadFarmers();
            },
            showFarmerDialog(farmer) {
                if (farmer) {
                    this.farmer = JSON.parse(JSON.stringify(farmer));
                }
                else {
                    this.farmer = {
                        registeredUser: {},
                        creditAttachments: [],
                        hasRisk: false,
                        discreditAttachments: [],
                        otherAttachments: [],
                    };
                }
                this.farmerDialogVisible = true;
            },
            selectRegisteredUser(user) {
                this.farmer.registeredUser.id = user.id;
                this.farmer.registeredUser.name = user.name;
                this.farmer.legalPersonName = user.legalPersonName;
                this.farmer.legalPersonPhone = user.phone;
                this.farmer.contactName = user.name;
                this.farmer.contactPhone = user.phone;
            },
            async saveFarmer() {
                if (this.farmer.id == undefined) {
                    await this.$axios.post('/api/Farmer/AddEnterpriseFarmer', {
                        registeredUserId: this.farmer.registeredUser.id,
                        legalPersonName: this.farmer.legalPersonName,
                        legalPersonPhone: this.farmer.legalPersonPhone,
                        contactName: this.farmer.contactName,
                        contactPhone: this.farmer.contactPhone,
                        creditAttachments: this.farmer.creditAttachments,
                        hasRisk: this.farmer.hasRisk,
                        discreditAttachments: this.farmer.discreditAttachments,
                        otherAttachments: this.farmer.otherAttachments,
                    });
                    this.farmerDialogVisible = false;
                    this.loadFarmers();
                }
                else {
                    await this.$axios.post('/api/Farmer/UpdateEnterpriseFarmer', {
                        id: this.farmer.id,
                        legalPersonName: this.farmer.legalPersonName,
                        legalPersonPhone: this.farmer.legalPersonPhone,
                        contactName: this.farmer.contactName,
                        contactPhone: this.farmer.contactPhone,
                        creditAttachments: this.farmer.creditAttachments,
                        hasRisk: this.farmer.hasRisk,
                        discreditAttachments: this.farmer.discreditAttachments,
                        otherAttachments: this.farmer.otherAttachments,
                    });
                    this.farmerDialogVisible = false;
                    this.loadFarmers();
                }
            },
            async deleteFarmer(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Farmer/DeleteFarmer', { id: id });
                this.loadFarmers();
            },
        },
        created() {
            this.loadFarmers();
        },
    };
</script>